import { List, ListItem, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import algoliasearch from 'algoliasearch/lite';
import React, { useContext, useEffect } from 'react';
import {
  InstantSearch,
  useHits,
  useSearchBox,
} from 'react-instantsearch-hooks-web';

import Icon from '@/contents/General/Icon';
import Stack from '@/contents/General/Stack';
import TextField from '@/contents/General/TextField';
import Typography from '@/contents/General/Typography';
import { preventScroll } from '@/contents/hooks/preventScroll';
import { GlobalContext } from '@/contents/hooks/state';
import theme from '@/styles/theme';

const SuggestArea = styled(Box)<{ is_pc: string }>`
  height: calc(100vh - ${theme.spacing(8)});
  background: rgba(51, 51, 51, 0.8);
  ${(props) =>
    props.is_pc &&
    `
    border-radius: 40px 40px 0 0;
    overflow: hidden;
  `}
  ul {
    overflow-y: scroll;
    background: #f4f8f6;
    ${(props) =>
      props.is_pc
        ? `height: calc(100vh - ${theme.spacing(6)});`
        : `height: calc(100vh - ${theme.spacing(8)} - 56px);`}
  }
  ul > li:not(:last-child) {
    border-bottom: solid 1px #e3e9e6;
  }
`;

const SuggestItem = styled(ListItem)`
  display: flex;
  align-items: center;
  padding: 0 ${theme.spacing(3)};
  height: 56px;
`;
const SearchContent = ({ onClose }: { onClose: () => void }) => {
  const { suggestText, setSuggestText } = useContext(GlobalContext);
  const { refine } = useSearchBox();
  const isPC = useMediaQuery('(min-width:900px)');

  useEffect(() => {
    if (suggestText === '') {
      preventScroll(false);
      return;
    }
    preventScroll(true);
    refine(suggestText);
  }, [suggestText]);

  const { hits } = useHits();
  return (
    <>
      {!isPC && (
        <Box sx={{ padding: theme.spacing(3), background: '#E3E9E6' }}>
          <Stack space={3} sx={{ width: '100%' }}>
            <IconButton onClick={() => onClose()}>
              <Icon iconType="close-gr" />
            </IconButton>
            <TextField
              placeholder="キャンプ場名やエリアを入力"
              value={suggestText}
              onChange={(e) => setSuggestText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon iconType="search" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Box>
      )}

      <List>
        {hits.map((hit) =>
          hit.type === 'theme' ? (
            <a
              href={`/theme/${hit.objectID}`}
              onClick={() => {
                onClose();
              }}
              key={hit.objectID}
            >
              <SuggestItem>
                <Typography fontSize={15} fontWeight={500} color="primary">
                  <span>{hit.title as string}のおすすめキャンプ場</span>
                </Typography>
              </SuggestItem>
            </a>
          ) : (
            <a
              href={`/camp/${hit.objectID}`}
              onClick={() => {
                onClose();
              }}
              key={hit.objectID}
            >
              <SuggestItem>
                <Typography fontSize={15} fontWeight={500} color="primary">
                  <span>{hit.name as string}</span>
                </Typography>
              </SuggestItem>
            </a>
          )
        )}
      </List>
    </>
  );
};

const Search = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const isPC = useMediaQuery('(min-width:900px)');
  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_KEY as string,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string
  );
  return (
    <SuggestArea is_pc={isPC ? isPC.toString() : ''}>
      <InstantSearch
        indexName={
          process.env.NEXT_PUBLIC_ALGOLIA_CAMPSITE_INDEX_NAME as string
        }
        searchClient={searchClient}
      >
        <SearchContent onClose={onClose} />
      </InstantSearch>
    </SuggestArea>
  );
};
export default Search;
