import type { ChipProps } from '@mui/material';
import { Chip as MuiChip } from '@mui/material';
import { styled } from '@mui/material/styles';

import theme from '@/styles/theme';

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    white: true;
    red: true;
    green: true;
  }
}

type Props = ChipProps & { chipSize?: number; chipColor?: string };

const StyledChip = styled(MuiChip)<{ chipcolor?: string; chipsize?: number }>`
  padding: ${(props) => (props.chipsize ? '0 8px' : '0 5px')};
  height: ${(props) => (props.chipsize ? `${props.chipsize}px` : '16px')};
  color: #818982;
  background: #e3e9e6;

  ${(props) =>
    props.chipcolor === 'primary' &&
    `color: ${theme.palette.white.main};
      background: ${theme.palette.primary.main};
  `}
  ${(props) =>
    props.chipcolor === 'secondary' &&
    `color: ${theme.palette.white.main};
      background: ${theme.palette.secondary.main};
  `}
  ${(props) =>
    props.chipcolor === 'required' &&
    `color: ${theme.palette.red.main};
      background: #f8e0cf;
  `}
  ${(props) =>
    props.chipcolor === 'red' &&
    `color: ${theme.palette.white.main};
      background: ${theme.palette.red.main};
  `}
  ${(props) =>
    props.chipcolor === 'white' &&
    `color: ${theme.palette.primary.main};
      background: ${theme.palette.white.main};
  `}
  ${(props) =>
    props.chipcolor === 'blue' &&
    `color: ${theme.palette.primary.main};
      background: #E2EDF5;
  `}
  > span {
    padding: unset;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
  }
`;

const Chip = ({ label, chipSize, chipColor, sx }: Props) => {
  return (
    <StyledChip
      label={label}
      chipsize={chipSize}
      chipcolor={chipColor}
      sx={sx}
    />
  );
};

export default Chip;
