import { IconButton, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';

import Button from '@/contents/General/Button';
import Icon from '@/contents/General/Icon';
import Stack from '@/contents/General/Stack';
import { GlobalContext } from '@/contents/hooks/state';
import Search from '@/modules/CampSite/Seach';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';
import theme from '@/styles/theme';

const Container = styled(Box)`
  position: fixed;
  width: 100%;
  max-width: inherit;
  z-index: 11;
` as typeof Box;

const HeaderArea = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.spacing(2)};
  width: inherit;
  height: 64px;
  max-width: inherit;
  background: #ffffff;
  @media (min-width: 900px) {
    display: none;
  }
`;

export const Header = () => {
  const router = useRouter();
  const { setDialogStatus, openSuggest, setOpenSuggest } =
    useContext(GlobalContext);
  const { user, isLogin } = useGetLoginUser();
  const isPC = useMediaQuery('(min-width:900px)');
  const isSmallMobile = useMediaQuery('(max-width:360px)');

  const openGlobalNavi = () => {
    setDialogStatus({
      open: true,
      type: 'GLOBAL_NAVI',
      props: {},
    });
  };

  useEffect(() => {
    if (process.browser) {
      document.body.removeAttribute('style');
    }
  }, [router]);

  useEffect(() => {
    setOpenSuggest(false);
  }, [router]);

  useEffect(() => {
    if (openSuggest) {
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      document.body.style.inset = '0px';
    } else {
      document.body.removeAttribute('style');
    }
  }, [openSuggest]);

  return (
    <Container sx={isPC ? { mt: '-1px' } : {}}>
      <HeaderArea>
        <Button onClick={() => router.push('/')} component="label">
          <img
            src="/assets/images/common/logo-header.svg"
            width={121}
            height={34}
            alt="iihi logo"
          />
        </Button>
        <Stack alignCenter space={1}>
          {user === null && (
            <Stack alignCenter space={1}>
              <Button
                onClick={() => router.push('/login')}
                buttonSize="sm"
                sx={isSmallMobile ? { minWidth: '48px', padding: '0 8px' } : {}}
              >
                ログイン
              </Button>
              <Button
                onClick={() => router.push('/login/?status=signup')}
                buttonSize="sm"
                color="secondary"
                sx={isSmallMobile ? { minWidth: '48px', padding: '0 8px' } : {}}
              >
                会員登録
              </Button>
            </Stack>
          )}
          <IconButton onClick={() => setOpenSuggest(!openSuggest)}>
            <Icon size={25} iconType="search" />
          </IconButton>
          <IconButton onClick={openGlobalNavi}>
            <Icon iconType="menu" />
          </IconButton>
        </Stack>
      </HeaderArea>

      {openSuggest && (
        <Search
          open={openSuggest}
          onClose={() => {
            setOpenSuggest(false);
          }}
        />
      )}
    </Container>
  );
};
