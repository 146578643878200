import useGetLoginUser from './user/useGetLoginUser';
import useUserNotification from './user/useUserNotification';

export default function useUserNotificationNum() {
  const { data: userNotificationData } = useUserNotification();
  const { user: loginUser } = useGetLoginUser();
  const totalUnReadNum =
    userNotificationData?.user_notification_aggregate.aggregate?.count ?? 0;
  const userStartHistory = loginUser?.start_history ?? '';

  return {
    totalNum: totalUnReadNum + (!userStartHistory ? 1 : 0),
    totalUnReadNum,
    isEdited: !!userStartHistory,
  };
}
