import { gql } from 'graphql-request';

import { useUserNotificationQuery } from '@/__generated__/user-graphql';
// export default (
//     options: UseQueryOptions<CampsiteLikeQuery, unknown, CampsiteLikeQuery>
//   ) => {
//     return useCampsiteLikeQuery(undefined, {
//       ...options,
//       cacheTime: 60 * 10,
//     });
//   };
export default () => {
  return useUserNotificationQuery(undefined, {});
};
const query = gql`
  query UserNotification {
    user_notification(where: { read_at: { _is_null: true } }) {
      user_id
      updated_at
      type
      read_at
      id
      created_at
      review_like_values
    }
    user_notification_aggregate(where: { read_at: { _is_null: true } }) {
      aggregate {
        count
      }
    }
  }
`;
