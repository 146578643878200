import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import Button from '@/contents/General/Button';
import Chip from '@/contents/General/Chip';
import Icon from '@/contents/General/Icon';
import Image from '@/contents/General/Image';
import { UserIcon } from '@/contents/General/Panels';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import useSearchCampSite from '@/modules/CampSite/public/useSearchCampSite';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';
import useUserNotificationNum from '@/modules/User/useUserNotificationNum';
import theme from '@/styles/theme';
import { Page } from '@/types/domain';
import { getBlankUserIcon } from '@/utils/GetBlankString';
import ImageUrlConvert from '@/utils/ImageUrlConvert';

const Container = styled(Box)`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 12px 24px 24px;
  width: 100%;
  height: 80px;
  max-width: inherit;
  background: #e3e9e6;
  z-index: 10;
  ${theme.breakpoints.up('md')} {
    display: none;
  }
`;

const CountBox = styled(Box)`
  width: 88px;
  height: 46px;
  border-radius: 4px;
  background: #ffffff;
  > div:nth-of-type(1) {
  }
  > div:nth-of-type(2) {
    border-radius: 0 0 4px 4px;
    background: ${theme.palette.primary.main};
    ::after {
      content: '';
      display: block;
      position: relative;
      left: 2px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 5px solid #ffffff;
    }
  }
`;

const TotalNumComponent = ({ userId }: { userId: string }) => {
  const { totalNum } = useUserNotificationNum();
  if (totalNum === 0) return <></>;
  return (
    <Chip
      label={`${totalNum}`}
      chipColor="red"
      sx={{
        position: 'absolute',
        top: '-4px',
        left: '24px',
      }}
    />
  );
};

export const BottomMenu = () => {
  const router = useRouter();
  const { page } = useContext(GlobalContext);
  const { user, isLogin } = useGetLoginUser();
  const { data } = useSearchCampSite(
    {
      limit: 1,
      where: {
        main_image_id: {
          _is_null: true,
        },
      },
    },
    {
      cacheTime: 60 * 60 * 1000,
    }
  );
  const num = data?.campsite_aggregate.aggregate?.count ?? 0;

  return (
    <Container>
      <CountBox onClick={() => router.push('/search/notentered/')}>
        <Stack justifyCenter alignEnd>
          <Typography fontSize={18} fontWeight={900} color="primary">
            {num ?? 0}
          </Typography>
          <Typography
            fontSize={9}
            fontWeight={500}
            color="#818982"
            sx={{ marginLeft: '2px', marginBottom: '4px' }}
          >
            施設
          </Typography>
        </Stack>
        <Stack center sx={{ height: '17px' }}>
          <Typography fontSize={10} fontWeight={500} color="#ffffff">
            情報募集中！
          </Typography>
        </Stack>
      </CountBox>
      <Stack width="64vw" around>
        <Button onClick={() => router.push('/theme')} component="label">
          <Stack column center>
            <Icon
              iconType="tent"
              color={page === Page.THEME ? 'primary' : ''}
            />
            <Typography
              fontSize={9}
              fontWeight="Medium"
              color={page === Page.THEME ? 'primary' : 'grey'}
            >
              見つける
            </Typography>
          </Stack>
        </Button>
        <Button onClick={() => router.push('/search')} component="label">
          <Stack column center>
            <Icon
              iconType="switch"
              color={page === Page.SEARCH ? 'primary' : ''}
            />
            <Typography
              fontSize={9}
              fontWeight="Medium"
              color={page === Page.SEARCH ? 'primary' : 'grey'}
              whiteSpace="nowrap"
            >
              条件から探す
            </Typography>
          </Stack>
        </Button>
        <Button
          onClick={() =>
            router.push(isLogin ? `/camper/${user!.id}/favorite` : `/login`)
          }
          component="label"
        >
          <Stack column center>
            <Icon
              iconType="favorite-base"
              size={32}
              color={page === Page.MYPAGE_FAVORITE ? 'primary' : ''}
            />
            <Typography
              fontSize={9}
              fontWeight="Medium"
              color={page === Page.MYPAGE_FAVORITE ? 'primary' : 'grey'}
            >
              お気に入り
            </Typography>
          </Stack>
        </Button>
        <Button
          onClick={() =>
            router.push(isLogin ? `/camper/${user!.id}` : `/login`)
          }
          component="label"
        >
          <Stack column center>
            <Box
              position="relative"
              borderRadius={'50%'}
              width={32}
              height={32}
            >
              {!!user && typeof user === 'object' && (
                // @FIXME: 通知＆設定のフラグ数値を載せる
                <TotalNumComponent userId={user!.id!} />
              )}
              {isLogin && user?.icon?.file_name ? (
                <UserIcon icon_height={32}>
                  <Image
                    src={ImageUrlConvert(user?.icon?.file_name, true)}
                    width={32}
                    height={32}
                    alt={`${user.display_name}のアイコン`}
                  />
                </UserIcon>
              ) : (
                <Image
                  src={getBlankUserIcon()}
                  width={32}
                  height={32}
                  alt="未設定のユーザーアイコン"
                />
              )}
            </Box>
            <Typography
              fontSize={9}
              fontWeight="Medium"
              color={page === Page.MYPAGE_ACCOUNT ? 'primary' : 'grey'}
            >
              マイページ
            </Typography>
          </Stack>
        </Button>
      </Stack>
    </Container>
  );
};
